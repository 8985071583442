var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"createClient"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('DetallesCliente')))])]),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"client-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CodigoERP'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CodigoERP'),"label-for":"client-codERP"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CodigoERP')},model:{value:(_vm.codCliERP),callback:function ($$v) {_vm.codCliERP=$$v},expression:"codCliERP"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CIF'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CIF'),"label-for":"client-cif"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CIF')},model:{value:(_vm.cif),callback:function ($$v) {_vm.cif=$$v},expression:"cif"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Direccion'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Direccion'),"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Direccion')},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CodigoPostal'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CodigoPostal'),"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CodigoPostal')},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Region'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Region'),"label-for":"client-region"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Region')},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pais'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Pais'),"label-for":"client-country"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Pais')},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Ciudad'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Ciudad'),"label-for":"client-city"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Ciudad')},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Telefono'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefono'),"label-for":"client-phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Telefono')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"client-email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Email')},model:{value:(_vm.emailInput),callback:function ($$v) {_vm.emailInput=$$v},expression:"emailInput"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Web'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Web'),"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Web')},model:{value:(_vm.web),callback:function ($$v) {_vm.web=$$v},expression:"web"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Imagen'),"label-for":"client-image"}},[_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.files,"maxFiles":"1"}})],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Enviar'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }